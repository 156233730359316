import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { palletCountUnits, palletCountLabel } from "../components/units";
import { FormStepperProvider } from "src/components/form/FormStepper";

const PackageFormContext = createContext();

export function PackageFormProvider({ children, packageIndex }) {
  const { watch, getValues } = useFormContext();

  const item = getValues(`items.${packageIndex}`);

  const InitialFormState = useMemo(
    () => ({
      isShowSpecification: item?.description ?? false,
      isShowWeight:
        item?.actualHeight && item?.actualWidth && item?.actualLength,
      isShowQuantity: item?.actualWeight ?? false,
      isShowAdditionalInfo: item?.actualQty ?? false,
      quantityLabel: item?.actualQtyUOM
        ? palletCountLabel[
            palletCountUnits.findIndex((v) => v === item.actualQtyUOM)
          ]
        : palletCountLabel[12],
      isBasicInfoSet: !!(
        item?.itemId &&
        item?.actualValue &&
        item?.description
      ),
      areThereSubItems: !!item?.subItems?.length,
    }),
    [item]
  );

  const state = useMemo(
    () => {
      const item = getValues(`items.${packageIndex}`);
      return ({
      isShowSpecification: item?.description ?? false,
      quantityLabel: item?.actualQtyUOM
        ? palletCountLabel[
            palletCountUnits.findIndex((v) => v === item.actualQtyUOM)
          ]
        : palletCountLabel[12],
      isShowWeight:
        item?.actualLength && item?.actualWidth && item?.actualHeight,
      isShowQuantity: item?.actualWeight ?? false,
      isShowAdditionalInfo: item?.actualQty ?? false,
      isBasicInfoSet: !!(
        item?.itemId &&
        item?.actualValue &&
        item?.description
      ),
      areThereSubItems: !!item?.subItems?.length,
    })},
    [watch(), watch(`items.${packageIndex}.subItems`)]
  );

  const isHazmatCargo = watch('services.hazardousCargoCharge');
  const isHazmatPackage = watch(`items.${packageIndex}.isHazmat`);

  const steps = useMemo(
    () => [
      {
        label: "Basic Info",
        completed: state.isBasicInfoSet,
        isValid: !!(
          state.isShowWeight &&
          state.isShowQuantity &&
          state.isShowAdditionalInfo
        ),
      },
      {
        label: "Items",
        completed: state.areThereSubItems,
        isValid: state.areThereSubItems,
        optional: !state.areThereSubItems ? "Required" : "",
      },
    ],
    [state]
  );

  return (
    <FormStepperProvider steps={steps}>
      <PackageFormContext.Provider
        value={{ state, packageIndex, isHazmatCargo, isHazmatPackage }}
      >
        {children}
      </PackageFormContext.Provider>
    </FormStepperProvider>
  );
}

export function usePackageForm() {
  const context = useContext(PackageFormContext);

  if (!context) {
    throw new Error("usePackageForm must be used within a PackageFormProvider");
  }

  return context;
}
