import { Box } from "@mui/material";
import { useActionables } from "./actionables";
import DialogUncombineShipment from "./DialogUncombineShipment";
import DialogConfirmForShipments from "./DialogConfirmForShipments";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useState } from "react";
import DialogAppointmentTime from "./DialogAppointmentTime";
import DialogCandidateCarrier from "./DialogCandidateCarrier";

export function ShipmentActions() {
  const { shipment, uncombine, pendingShipment, confirmShipment, addCandidateCarrier } = useShipmentDetails();

  const actionables = useActionables({
    onUncombineTapped: () => {
      setUncombineConfirmDialogVisilibity(true)
    },
    onToPendingTapped: () => {
      setToPendingConfirmDialogOpened(true)
    },

    onAppointmentRequired: () => {
      setOpenDialogAppointTime(true);
    },
    onCandidateCarrier: () => {
      setOpenDialogCandidateCarrier(true);
    },    

  });

  const [isUncombineConfirmDialogOpened, setUncombineConfirmDialogVisilibity] =
    useState(false);
  const [openDialogAppointTime, setOpenDialogAppointTime] = useState(false);
  const [openDialogCandidateCarrier, setOpenDialogCandidateCarrier ] = useState(false);

  const appointmentPickup = shipment.services?.needsAppointmentPickup;
  const appointmentStop = shipment.services?.needsAppointmentStop;
  
  const [isToPendingConfirmDialogOpened, setToPendingConfirmDialogOpened] = useState(false);

  const pendingShip = (rate) => {
    if (rate) {
      rate = String(rate).replace(/,/g, ''); 
      rate = parseFloat(rate);
    }
    setToPendingConfirmDialogOpened(false);
    pendingShipment({ shipmentId: shipment._id, rate: rate });
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {actionables
          .filter((action) => action.isShown)
          .map(({ Actionable, key }) => (
            <Actionable key={key} />
          ))}
      </Box>
      <DialogUncombineShipment
        onConfirm={() => {
          setUncombineConfirmDialogVisilibity(false);
          uncombine({ shipmentId: shipment._id });
        }}
        onClose={() => {
          setUncombineConfirmDialogVisilibity(false);
        }}
        open={isUncombineConfirmDialogOpened}
      />
      <DialogConfirmForShipments
        title={"Add to Pending"}
        text={"Are you sure want to set this shipment as Pending?"}
        onConfirm={(rate) => { pendingShip(rate) }}
        onClose={() => { setToPendingConfirmDialogOpened(false) }}
        open={isToPendingConfirmDialogOpened}
        inputRate
      />
      <DialogAppointmentTime
        onConfirm={(selectedAppointmentTimePickup, selectedAppointmentTimeStop) => {
          setOpenDialogAppointTime(false);
          confirmShipment(null, null, null, null, selectedAppointmentTimePickup, selectedAppointmentTimeStop);
        }}
        onClose={() => {
          setOpenDialogAppointTime(false);
        }}
        open={openDialogAppointTime}
        appointmentPickup={appointmentPickup}
        appointmentStop={appointmentStop}
      />
      <DialogCandidateCarrier
        onConfirm={(rateCarrier, mcCarrier, etaCarrier, emptyOn) => {
          setOpenDialogCandidateCarrier(false);          
          addCandidateCarrier(rateCarrier, mcCarrier, etaCarrier, emptyOn)
        }}
        onClose={() => {
          setOpenDialogCandidateCarrier(false);
        }}
        open={openDialogCandidateCarrier}  
        shipmentId={shipment._id}      
        useFormParams={{ defaultValues: null }}
      />
    </>
  );
}
