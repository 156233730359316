import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { PONumberField } from "../PONumberField/PONumberField";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { OrderService } from "src/api/orders.service";
import { useShipmentFormContext } from "../../../hooks/useForm";
import { PackagePOMapper } from "../PackageFields/PackagePOMapper";
import { useTenant } from "src/hooks/useTenant";

export const InitialForm = ({ onDone, onChange }) => {
    const InitialState = {
        isPalletized: null,
        isMoreThan5Pallets: null,
    };
    const [state, setState] = useState(InitialState);
    const { setValue, getValues } = useFormContext();
    const [params] = useSearchParams();

    const {
        form: { watch },
        addOrder,
    } = useShipmentFormContext();

    const { session } = useAuth();
    
    const isWarehouseManager = session.permissions.IsWarehouseManager;

    const service = new OrderService(session.token, session.tenant._id);

    const setPalletized = (value) => {
        const isPalletized = value === "yes";
        const newState = { ...state, isPalletized };
        setState(newState);
        onChange(newState);
    };

    const setAmount = (value) => {
        const isMoreThan5Pallets = value === "yes";
        const newState = { ...state, isMoreThan5Pallets };
        setState(newState);
        onChange(newState);
    };

    const orders = params.get("orders");

    useEffect(() => {
        const execute = async () => {
            if (orders) {
                const result = await service.getOrdersById(orders);
                if (result) {
                    result.forEach((data) => {
                        addOrder(data);
                        const poNumber = `${data.PO_NUM}-${data.RELEASE_NUM ?? 0}`;
                        const poNumbers = getValues('poNumber')
                        poNumbers.push(poNumber);
                        setValue("poNumber", poNumbers)
                    })
                }
            }
        };
        execute();
    }, [orders]);

    const poNumbers = useMemo(() => {
        return watch('poNumber')
    }, [watch('poNumber')]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "32",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mb: "2rem",
                }}
            >
                <Typography
                    fontWeight={600}
                    variant="h1"
                    sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
                >
                    Let's create your shipment
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    mb: "1rem",
                }}
            >
                <Typography variant="caption" fontSize={"1.2rem"}>
                    Quick questions
                </Typography>
                {!isWarehouseManager && (
                    <FormControl>
                        <FormLabel sx={{ mb: 1 }}>Specify your PO Numbers</FormLabel>
                        <PONumberField />
                    </FormControl>
                )}
                {poNumbers.length > 0 &&
                    <PackagePOMapper />
                }
                <FormControl>
                    <FormLabel id="palletized">Is this shipment palletized?</FormLabel>
                    <RadioGroup
                        aria-labelledby="palletized-label"
                        name="palletized"
                        row
                        onChange={(event) => setPalletized(event.target.value)}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
                {state.isPalletized && (
                    <FormControl>
                        <FormLabel id="amount">Are there more than 5 pallets?</FormLabel>
                        <RadioGroup
                            aria-labelledby="more-than-5-pallets-label"
                            name="amount"
                            row
                            onChange={(event) => setAmount(event.target.value)}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>

                )}
                <Box>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => onDone(state)}
                        fullWidth
                        disabled={
                            state.isPalletized === null ||
                            (state.isPalletized && state.isMoreThan5Pallets === null)
                        }
                        sx={{ height: "2.5rem", mt: "2rem", mb: "1rem" }}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
