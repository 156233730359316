import { Box, Button, FormControl, FormControlLabel, FormLabel, MenuItem, Switch, Typography } from "@mui/material";
import { PackagePOMapper } from "../PackageFields/PackagePOMapper";
import { Equipments } from "src/pages/shipments/constants/equipments";
import { BasicPONumberField } from "../PONumberField/BasicPONumberField";
import { LocationSearchField } from "../LocationSearchField/LocationsSearchField";
import { useEffect, useState } from "react";
import { FormInput } from "src/components/form/FormInput";
import { Controller, useFormContext } from "react-hook-form";

export const SpiersInitialForm = ({ onDone }) => {
    const [state, setState] = useState({
        isShownEquipment: false,
        isShownAlfredId: false,
        isShownPackages: false,
        isShownLocations: false,
        isValid: false,
        isExpedited: false,
    });

    const { control, watch, getValues, setValue } = useFormContext();

    const mapEquipment = (selected) => {
        const equipments = getValues('equipment');
        const nonSelecteds = Object.keys(equipments).filter(eq => eq !== selected && eq !== 'selected');
        nonSelecteds.forEach(key => setValue(`equipment.${key}`, false));
        setValue(`equipment.${selected}`, true);
    }

    useEffect(() => {
        const mode = getValues('mode');
        const equipment = getValues('equipment.selected');
        const poNumber = getValues('poNumber');
        const items = getValues('items');
        const pickup = getValues('pickups.0.originName');
        const stop = getValues('stops.0.destName');
        const expedited = getValues('services.expedited');

        if (!!equipment) mapEquipment(equipment);

        const isShownEquipment = !!mode && mode !== 'LTL';
        const isShownAlfredId = !!equipment || (!!mode && mode === 'LTL');
        const isShownPackages = !!(poNumber && poNumber.length);
        const isShownLocations = !!(items && items.length);
        const isExpedited = expedited;

        if (expedited) {
            setValue('mode', 'TL')
        }

        setState({
            ...state,
            isShownEquipment,
            isShownAlfredId,
            isShownPackages,
            isShownLocations,
            isValid: !!pickup && !!stop,
            isExpedited,
        });
    }, [
        watch('mode'),
        watch('equipment.selected'),
        watch('poNumber'),
        watch('items'),
        watch('pickups.0.originName'),
        watch('stops.0.destName'),
        watch('services.expedited')
    ]);

    

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "32",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mb: "2rem",
                }}
            >
                <Typography
                    fontWeight={600}
                    variant="h1"
                    sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
                >
                    Let's create your shipment
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    mb: "1rem",
                }}
            >
                <Controller
                    name={`services.hazardousCargoCharge`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"This is a hazardous cargo charge"}
                        />
                    )}
                />

                <Controller
                    name={`services.expedited`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"This is a expedited shipment"}
                        />
                    )}
                />


                <FormControl sx={{ gap: '0.5rem' }}>
                    <FormLabel>Which is the mode for this shipment?</FormLabel>
                    <FormInput
                        label={'Mode'}
                        name={'mode'}
                        select
                        disabled={state.isExpedited}
                    >
                        {['TL', 'LTL'].map((mode) => (
                            <MenuItem key={mode} value={mode}>
                                {mode}
                            </MenuItem>
                        ))}
                    </FormInput>
                </FormControl>
                {state.isShownEquipment && (
                    <FormControl sx={{ gap: '0.5rem' }}>
                        <FormLabel>Select the equipment to be used</FormLabel>
                        <FormInput
                            label={'Equipment'}
                            name={'equipment.selected'}
                            select
                        >
                            {Equipments.map((eq) => (<MenuItem key={eq.key} value={eq.key}>{eq.label}</MenuItem>))}
                        </FormInput>
                    </FormControl>
                )}
                {state.isShownAlfredId && (
                    <FormControl>
                        <FormLabel sx={{ mb: 1 }}>Specify your Alfred ID(s)</FormLabel>
                        <BasicPONumberField label={'Alfred ID (Press Enter)'} />
                    </FormControl>
                )}
                {state.isShownPackages && <PackagePOMapper unrestricted />}
                {state.isShownLocations && (
                    <FormControl sx={{ gap: '0.5rem' }}>
                        <FormLabel>Search for your pickup and delivery locations</FormLabel>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem' }}>
                            <Box sx={{ width: '100%' }}>
                                <LocationSearchField
                                    name={`pickups.0.originName`}
                                    locationType={'pickup'}
                                    label="Pickup Location"
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <LocationSearchField
                                    name={`stops.0.destName`}
                                    locationType={'delivery'}
                                    label="Delivery Location"
                                />
                            </Box>
                        </Box>
                    </FormControl>
                )}
                <Box>
                    <Button
                        variant="contained"
                        type="button"
                        onClick={() => onDone()}
                        fullWidth
                        disabled={!state.isValid}
                        sx={{ height: "2.5rem", mt: "2rem", mb: "1rem" }}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}