import {
  ControlPointDuplicate,
  LocalShippingOutlined,
  TaskAltOutlined,
  RestartAlt,
  ErrorOutline,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useState } from "react";
import DialogResetShipmentStatus from "./DialogResetShipmentStatus";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";

export function useActionables({ onUncombineTapped, onToPendingTapped, onAppointmentRequired, onCandidateCarrier }) {

  const { session } = useAuth();
  const { tenant } = useTenant();

  const {
    shipment,
    confirmShipment,
    isConfirmationLoading,
    isBackofficeUser,    
    isLTLManager,
    resetShipmentStatus,
    isResettingShipmentLoading,
    isUncombining,
    isAllowedToBook
  } = useShipmentDetails();

  const [openDialogResetStatus, setOpenDialogResetStatus] = useState(false);

  const needsValidation =
    !!(shipment?.poNumber?.length > 0) &&
    !shipment.validated &&
    ((shipment.mode == "TL" &&
      !shipment.integrations?.find(
        (integration) => integration?.target == "trinity"
      )?.status == "success") ||
      (shipment.mode == "LTL" &&
        false && // TODO Remove when migrate validation to booking method.
        shipment.integrations?.find(
          (integration) => integration?.target == "banyan"
        )?.status == "success"));

  const toggleToPendingModalVisibility = () => onToPendingTapped();
  const toggleUncombineModalVisibility = () => onUncombineTapped();
  const toggleAppointmentModalVisibility = () => onAppointmentRequired();
  const toggleCandidateCarrierModalVisibility = () => onCandidateCarrier();

  const actionables = [
    {
      key: "confirm-shipment",
      isShown: ['TL', 'PAR'].includes(shipment.mode) && (isBackofficeUser || (isAllowedToBook && !tenant.code === 'SNT')) && !needsValidation && !shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined />}
            onClick={() => {
              if (
                (shipment?.services?.needsAppointmentPickup ||
                  shipment?.services?.needsAppointmentStop) &&
                isBackofficeUser
              ) {
                toggleAppointmentModalVisibility();
              } else {
                confirmShipment();
              }
            }}
            variant="outlined"
            color={"primary"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirm Shipment"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "confirmed-shipment",
      isShown: isAllowedToBook && !needsValidation && shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            startIcon={<TaskAltOutlined />}
            disabled={true}
            variant="outlined"
            color={"success"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirmed"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "reconfirm-shipment",
      isShown: isAllowedToBook && shipment.integrated,
      Actionable: () => (
        shipment.mode === "LTL" ? <></> : <Tooltip title="Update Confirmation">
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined width={"2rem"} />}
            onClick={confirmShipment}
            variant="outlined"
            color={"primary"}
          >
            Reconfirm
          </LoadingButton>
        </Tooltip>
      )
    },
    {
      key: "duplicate-shipment",
      isShown: !isLTLManager,
      Actionable: () => (
        <Tooltip title="Duplicate Shipment">
          <Button
            href={`/shipments/${shipment._id}/edit?duplicate`}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            <ControlPointDuplicate />
            Duplicate
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "pending-shipment",
      isShown: !shipment?.metadata?.pending && session?.actions?.['SetPendingShipments'],
      Actionable: () => (
        <Tooltip title='Add to Pending' >
          <Button
            onClick={toggleToPendingModalVisibility}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <ErrorOutline />
            Add To Pending
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "reset-shipment-status",
      isShown:
        isBackofficeUser &&        
        shipment.integrated,
      Actionable: () => (
        <Tooltip title="Reset Shipment Status">
          <LoadingButton
            onClick={() => {
              setOpenDialogResetStatus(true);
            }}
            loading={isResettingShipmentLoading}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            <RestartAlt />
            Reset Shipment Status
          </LoadingButton>
          <DialogResetShipmentStatus
            onConfirm={() => {
              setOpenDialogResetStatus(false);
              resetShipmentStatus();
            }}
            onClose={() => {
              setOpenDialogResetStatus(false);
            }}
            open={openDialogResetStatus}
          />
        </Tooltip>
      ),
    },
    {
      key: "uncombine-shipment",
      isShown: isBackofficeUser && !isLTLManager && shipment.combined,
      Actionable: () => (
        <Tooltip title="Uncombine Shipment">
          <LoadingButton
            onClick={toggleUncombineModalVisibility}
            loading={isUncombining}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            Uncombine Shipment
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "mark-valid-shipment",
      isShown: shipment.metadata?.pending,
      Actionable: () => (
        <Tooltip title='Add Candidate Carrier' >
          <LoadingButton
            onClick={toggleCandidateCarrierModalVisibility}            
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Add Candidate Carrier
          </LoadingButton>
        </Tooltip >
      ),
    },
    // Temporarily disabling this
    // {
    //   key: "mark-valid-shipment",
    //   isShown: isBackofficeUser && shipment.metadata?.invalidPoNumber,
    //   Actionable: () => (
    //     <Tooltip title='Mark as Valid' >
    //       <LoadingButton
    //         onClick={validateShipment}
    //         loading={isValidateLoading}
    //         sx={{
    //           display: 'flex',
    //           gap: '0.5rem',
    //         }}
    //         variant="outlined"
    //       >
    //         Mark as Valid
    //       </LoadingButton>
    //     </Tooltip >
    //   ),
    // },
  ];

  return actionables;
}
