import { Box, Button, Divider } from "@mui/material";
import * as XLSX from 'xlsx'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { FileDownload } from "@mui/icons-material";

const handleExportXLS = (columns, rows, title) => {
  let values = ''
  const exportData = rows.map((row) => {
    const filteredRow = {};
    columns.forEach((col) => {
      if (!col.hide) {        
        let value = ''
        if (col.valueGetter) {
          value = col.valueGetter({row})
        } else {
          value = row[col.field];
        }        
        filteredRow[col.headerName] = value !== undefined ? value : '';
      }
    });
    return filteredRow;
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

function CustomToolbar({ columns, rows, title, actions, ...props }) {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: props.CustomToolbarActions? "100%" : "auto",
        }}
      >
        { props.CustomToolbarActions ? <props.CustomToolbarActions /> : null } 
        <Box>          
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <Button
            onClick={() => handleExportXLS(columns, rows, title)}
            variant="text"
            size="small"
            startIcon={<FileDownload/>}
            sx={{ textTransform: 'none' }}
          >
            Export
          </Button>
          { props.trailing ? <props.trailing /> : null } 
        </Box>
      </Box>
      <Box >
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          
        />
      </Box>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
