export const toShipmentUOM = (orderUOM) => {
    const options = {
        'BG': 'Bags',
        'BX': 'Boxes',
        'CTN': 'Cartons',
        'Each': 'Each',
        'Foot': 'Feet',
        'PK': 'Packet',
        'PAL': 'Pail(s)',
        'PL': 'Pallets',
        'PC': 'Pieces',
        'RL': 'Reels',
        'TB': 'Tub',  
    };

    const value = options[orderUOM ?? 'Each'];
    return value;
}