import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { OrderService } from "src/api/orders.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Order } from "src/models/order";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import { OrderDetailListItem } from "./SearchItemOrderDetails";

export const SearchPONumberField = ({ label, onUpdated, onSelect, exaustive, preload }) => {
    const { control, getValues } = useFormContext();
    const { setSnackbarMessage } = useSnackbar();
    const { removeOrder } = useShipmentFormContext();
    const { session } = useAuth();

    const [state, setState] = useState({
        loading: false,
        orders: [],
        open: false,
        error: { status: false, message: '' },
    });

    const setError = (status, message) => {
        setState({ ...state, error: { status, message } });
    }

    const setOrders = (orders) => {
        setState({ ...state, orders: orders });
    }

    const setLoading = (loading) => {
        setState({ ...state, loading: loading });
    }

    const setOpen = (open) => {
        setState({ ...state, open: open });
    }

    const onPoNumberSearchSuccess = (orders) => {
        setState({
            ...state,
            loading: false,
            orders,
        });
    }

    const onPoNumberSearchFailed = (message) => {
        setState({
            ...state,
            loading: false,
            orders: [],
            error: {
                status: true,
                message,
            }
        })
    }

    const abortControllerRef = useRef(null);
    const service = new OrderService(session.token, session.tenant._id);

    const options = useMemo(() => {
        if (state.loading || !state.orders) return [];

        let filtered = state.orders;
        if (!exaustive) {
            const selecteds = getValues('poNumber');
            filtered = state.orders.filter(order => {
                const poNumber = `${order.PO_NUM}-${order.RELEASE_NUM ?? 0}`;
                return !selecteds.includes(poNumber);
            })
        }

        if (!exaustive) setOpen(true);
        return filtered.map((order) => {
            return { label: `${Order.getPONumber(order)}`, ...order };
        });
    }, [state.orders, state.loading]);

    // Fetch PO Numbers on component mount if user is a SupplierManager
    useEffect(() => {
        if (preload) {
            const fetchPoNumbers = async () => {
                try {
                    const result = await service.getPoNumbers();
                    setOrders(result);
                } catch (err) {
                    setSnackbarMessage({
                        message: "Failed to fetch PO Numbers",
                        severity: "error",
                    });
                }
            };
            fetchPoNumbers();
        }
    }, [session]);


    const debounceEffect = debounce((poNumber) => {
        if (poNumber?.length && poNumber != "") {
            const fetchData = async () => {
                abortControllerRef.current?.abort();
                abortControllerRef.current = new AbortController();

                try {

                    const poNumbers = typeof (poNumber) === 'string' ? poNumber.split(',').filter(x => x) : poNumber;

                    const result = await service.postValidatePoNumbers(
                        poNumbers,
                        abortControllerRef.current?.signal
                    );

                    // If fetch is successful, update options
                    if (result?.validated?.length && result?.validated[0]?.orders?.length) {
                        const orders = result.validated.map(result => result.orders).flat()
                        onPoNumberSearchSuccess(orders);
                    } else {
                        onPoNumberSearchFailed("We coulnd't find this PO Number");
                    }
                } catch (err) {
                    if (err?.name == "AbortError") return;

                    onPoNumberSearchFailed("Failed to validate PO Number");
                }
            }
            fetchData();
        }
    }, 1500);

    function handleInputChange(event, orders) {
        if (state.error) setError(false, '');

        if (!event || !orders?.length) return;

        // Remove pre-populated values
        const sanitazedOrders = Array.isArray(orders) ? orders.filter(order => typeof (order) === 'string') : orders;

        // Check if input value matches any existing option
        const match = options.filter(option => option.label.includes(sanitazedOrders));

        if (!match.length) {
            // If no match, call debounceEffect to fetch from API
            debounceEffect(sanitazedOrders);
            setLoading(!!sanitazedOrders);
        }

        return () => debounceEffect.cancel();
    }

    function formatFieldValue(value) {
        if (Array.isArray(value)) {
            return value;
        }
        if (typeof value === "string") return [value];
        return [];
    };

    return <>
        <Controller
            name={"poNumber"}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const { onChange, onInputChange, value } = field;
                return (
                    <Autocomplete
                        {...field}
                        multiple
                        limitTags={1}
                        freeSolo
                        loading={state.loading}
                        fullWidth
                        open={state.open}
                        autoComplete
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        options={options}
                        filterOptions={(options, { inputValue }) => {
                            if (inputValue?.includes(',')) {
                                return options.filter(option => option.label);
                            }
                            return options.filter(option => option.label.includes(inputValue))
                        }}
                        includeInputInList
                        value={formatFieldValue(value)}
                        onChange={(event, orders) => {
                            const updatedOrders = orders.map((order) => order.label ? order.label : order);

                            if (exaustive) onChange(updatedOrders);
                            else handleInputChange(event, orders);

                            onUpdated(updatedOrders);

                            const removedItems = value?.find((item) => !updatedOrders.includes(item))
                            if (removedItems) {
                                removeOrder(removedItems.toString())
                            }
                        }}
                        onInputChange={(event, orders) => {
                            if (exaustive) {
                                handleInputChange(event, orders);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={formatFieldValue(value).length == 0 ? label : ""}
                                error={state.error.status}
                                helperText={state.error.message}
                                fullWidth
                                label={label}
                                {...error}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {state.loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <OrderDetailListItem
                                key={option._id}
                                customProps={props}
                                order={option}
                                onOrderSelect={(value) => onSelect(value)}
                            />
                        )}
                    />
                );
            }}
        />
    </>;
}