import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const SelectPickupField = ({ options, value, onChange }) => {

    return <FormControl fullWidth margin="normal" >
        <InputLabel id="select-pickup">Select the pickup</InputLabel>
        <Select
            labelId="select-pickup"
            value={value ?? {}}
            label="Select the pickup"
            onChange={onChange}
        >
            {options.map((pickup, index) => {
                return <MenuItem value={pickup} key={index}>{pickup.addr1}</MenuItem>
            })}
        </Select>
    </FormControl>;
}