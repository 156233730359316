import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export const useLocationForm = (props) => {
    const useFormParams = props?.useFormParams;

    const form = useForm({ ...useFormParams });
    const {
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setError,
        clearErrors,
    } = form;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSubmitSuccessful) props.reset && reset();
    }, [isSubmitSuccessful, reset, errors]);

    const onSubmitHandler = async (values) => {
        setIsLoading(true);
        try {
            const data = await props.onSave(values);
            if (data.error) {
                updateErrors(data.errors, setError);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const mapFields = (params, prefix = "") => {
        if (!params) return;
        Object.entries(params).forEach(([key, value]) => {
            if (!value) return;

            switch (key) {
                case "contacts":
                    const uniqueContacts = value.filter(
                        (contact) => !fieldsContact.some((field) => field.email === contact.email)
                    );
                    replaceContact(uniqueContacts);
                case "refOrder":
                    form.setValue(key, value ?? []);
                    return;
            }

            if (typeof value === "object")
                return mapFields(value, `${prefix + key}.`);

            form.setValue(prefix + key, value ?? false);
        });
    }

    const {
        fields: fieldsContact,
        remove: removeContact,
        append: appendContact,
        replace: replaceContact,
        move:   moveContact,
    } = useFieldArray({ control: form.control, name: "contacts" });

    const {
        fields: fieldsHolidayHours,
        remove: removeHolidayHours,
        append: appendHolidayHours,
    } = useFieldArray({ control: form.control, name: "operationHours.others" });

    return {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        setError,
        clearErrors,

        mapFields,

        fieldsContact,
        removeContact,
        appendContact,
        moveContact,

        fieldsHolidayHours,
        removeHolidayHours,
        appendHolidayHours,
    };
}

function updateErrors(errors, setError) {
    errors.forEach((error) => {
        setError(error.param, { type: "manual", message: error.msg });
    })
}