import { InitialForm } from "./InitialForm/CommonInitialForm";
import { useFormContext } from "react-hook-form";
import { useTenant } from "src/hooks/useTenant";
import { SpiersInitialForm } from "./InitialForm/SpiersInitialForm";

export const InitialFormBuilder = ({ onDone }) => {
  const { tenant } = useTenant();
  const { setValue } = useFormContext();

  const onChange = (state) => {
    setValue("mode", state.isPalletized && !state.isMoreThan5Pallets ? "LTL" : "TL");

    if (state.isMoreThan5Pallets) setValue("mode", "TL");
  }

  if (tenant.code === 'SNT') {
    return <SpiersInitialForm onDone={onDone} />
  }

  return <InitialForm
    onDone={onDone}
    onChange={onChange}
  />
};
